import React, { useState, useEffect } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Tab,
  TabPanel,
  Tabs,
  Text,
  ThemeProvider,
  makeStyles,
  LoadingOverlay,
} from '@knockrentals/knock-shared-web';
import { Tooltip } from '@material-ui/core';
import { Toaster } from '@knockrentals/knock-react';
import { useAppContext } from '../../Context/AppContext';
import { useFeatureFlagContext } from '../../Context/FeatureFlagContext';
import GetAppIcon from '@material-ui/icons/GetApp';
import VirtualAgentResponses from './NewResponses/VirtualAgentResponses';
import VirtualAgentSetupShortcuts from './NewSetupShortcuts/VirtualAgentSetupShortcuts';
import PropertiesAPI from '../Properties/PropertiesAPI';
import ProductEnabledSettings from './NewConfiguration/ProductEnabledSettings';
import { getIsAiEmailEnabled } from './aiEmailAPI';
import {
  getProductsEnabled,
  downloadProductsEnabledConfig,
  downloadInternalConfig,
  downloadVirtualAgentConfig,
  downloadLeasingPropertyResponses,
  downloadResidentPropertyResponses,
} from './VirtualAgentAPI';
import VirtualAgentConfigSettings from './NewConfiguration/VirtualAgentConfigSettings';
import InternalConfigSettings from './NewConfiguration/InternalConfigSettings';
import VirtualAgentResponsesDownloadModal from './NewResponses/Components/VirtualAgentResponsesDownloadModal';
import VirtualAgentConifg from './Configuration/VirtualAgentConfig';

const useStyles = makeStyles(() => ({
  tabPanel: {
    marginTop: '10px',
  },

  divider: {
    marginTop: '0 !important',
    position: 'relative',
    top: '-1px',
  },

  subtitle: {
    marginTop: '10px',
    marginBottom: '25px',
  },

  buttons: {
    paddingTop: '15px',
    display: 'flex',
    gap: '10px',
  },
}));

const TABS = {
  CONFIG: 'config',
  RESPONSES: 'responses',
  SETUP: 'setup',
  PRODUCTS: 'products',
  VIRTUAL_AGENT: 'virtual_agent',
  INTERNAL_CONFIG: 'internal_config',
};

const TABS_NAMES = {
  config: 'Configuration',
  responses: 'Responses',
  setup: 'Setup Shortcuts',
  products: 'Products',
  virtual_agent: 'Virtual Agent Config',
  internal_config: 'Internal Config',
};

const NewVirtualAgent = () => {
  const classes = useStyles();
  const { companyId, isInternalMode: internalMode } = useAppContext();
  const { isShowNewVAConfigurationUiEnabled } = useFeatureFlagContext();
  const [currentTab, setCurrentTab] = useState(
    internalMode && isShowNewVAConfigurationUiEnabled
      ? TABS.PRODUCTS
      : TABS.RESPONSES
  );
  const [downloadButtonText, setDownloadButtonText] = useState('');
  const [productsEnabled, setProductsEnabled] = useState([]);
  const [fetchedPropertyData, setFetchedPropertyData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorToaster, setShowErrorToaster] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadModalErrorMessage, setDownloadModalErrorMessage] =
    useState('');
  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    const getPropertyData = async () => {
      const { properties } = (await PropertiesAPI.getCommunityInfo()) || {};
      properties.sort((a, b) => {
        return a.data.location.name.localeCompare(b.data.location.name);
      });

      const propertyIds = properties.map(({ id }) => id);
      const aiEmailEnabledPropertyList = await getIsAiEmailEnabled(propertyIds);
      const chunkSize = 5;
      for (let i = 0; i < properties.length; i += chunkSize) {
        const chunk = properties.slice(i, i + chunkSize);
        const promises = await Promise.all(
          chunk.map(async (property) => {
            const {
              voice_product_enabled,
              sms_product_enabled,
              chat_product_enabled,
            } = await getProductsEnabled(companyId, property.id);
            const {
              ai_email_enabled: aiEmailEnabled,
              gen_ai_enabled: genAiEmailEnabled,
            } = aiEmailEnabledPropertyList.find(
              ({ property_id }) => property_id === property.id
            );

            return {
              propertyId: property.id,
              propertyName: property.data.location.name,
              voiceProductEnabled: voice_product_enabled,
              smsProductEnabled: sms_product_enabled,
              chatProductEnabled: chat_product_enabled,
              emailProductEnabled: aiEmailEnabled,
              aiEmailEnabled: aiEmailEnabled,
              genAiEmailEnabled: genAiEmailEnabled,
            };
          })
        );

        promises.forEach((propertyProductsEnabled) => {
          setProductsEnabled((prevState) => [
            ...prevState,
            propertyProductsEnabled,
          ]);
        });
      }
    };

    if (!fetchedPropertyData) {
      setIsLoading(true);

      getPropertyData()
        .catch((err) => {
          console.error(err);
          setShowErrorToaster(true);
        })
        .finally(() => {
          setFetchedPropertyData(true);
          setIsLoading(false);
        });
    }
  }, [companyId, fetchedPropertyData]);

  const getCSVFile = (csvResponse, filename) => {
    const blob = new Blob([csvResponse], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const download_link = Object.assign(document.createElement('a'), {
      href: url,
      download: filename,
      style: 'display: none',
    });

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadConfirmation = async (propertyIds) => {
    setIsDownloadModalOpen(false);
    setIsDownloading(true);

    const selectedProperties = productsEnabled.filter((product) =>
      propertyIds.includes(product['propertyId'])
    );

    try {
      let res;
      const properties = {};
      selectedProperties.forEach((property) => {
        properties[property.propertyId] = {
          property_name: property.propertyName,
          ai_email_enabled:
            property.emailProductEnabled === 'SETUP' ||
            property.emailProductEnabled === 'ON'
              ? true
              : false,
          is_gen_ai_email_enabled: property.genAiEmailEnabled,
        };
      });
      if (downloadButtonText === TABS_NAMES.products) {
        res = await downloadProductsEnabledConfig(companyId, properties);
      }

      if (downloadButtonText === TABS_NAMES.internal_config) {
        res = await downloadInternalConfig(companyId, properties);
      }

      if (downloadButtonText === TABS_NAMES.virtual_agent) {
        res = await downloadVirtualAgentConfig(companyId, properties);
      }

      if (downloadButtonText === TABS_NAMES.responses) {
        try {
          let residentCsvResponse = await downloadResidentPropertyResponses(
            selectedProperties
          );
          getCSVFile(residentCsvResponse, 'resident_responses.csv');
        } catch (err) {
          console.error('Error downloading resident responses:', err);
        }
        try {
          let leasingCsvResponse = await downloadLeasingPropertyResponses(
            selectedProperties
          );
          getCSVFile(leasingCsvResponse, 'leasing_responses.csv');
        } catch (err) {
          console.error('Error downloading leasing responses:', err);
        }
      } else {
        getCSVFile(res, `${downloadButtonText}.csv`);
      }
      Toaster.showToast(
        `${downloadButtonText} downloaded successfully`,
        2000,
        Toaster.ToastClasses.success
      );
    } catch (e) {
      Toaster.showToast(
        `An error occurred while downloading the ${downloadButtonText}.`,
        2000,
        Toaster.ToastClasses.error
      );
    }
    setIsDownloading(false);
  };

  return (
    <ThemeProvider>
      <Text variant="h6">Virtual Agent</Text>
      <Text variant="body1" className={classes.subtitle}>
        {TABS_NAMES[currentTab]}
      </Text>
      <Box>
        <Tabs
          value={currentTab}
          onChange={handleTabsChange}
          textColor="primary"
          indicatorColor="primary"
        >
          {internalMode && isShowNewVAConfigurationUiEnabled && (
            <Tab
              label={
                <Tooltip title="This information represents AI products ordered by the property and does NOT enable or activate the actual product.  Once toggled on, go to Virtual Agent Config to activate the AI products.">
                  <span>{TABS_NAMES.products}</span>
                </Tooltip>
              }
              value={TABS.PRODUCTS}
            />
          )}
          {internalMode && isShowNewVAConfigurationUiEnabled && (
            <Tab
              label={
                <Tooltip title="Settings to activate AI on ordered channels">
                  <span>{TABS_NAMES.virtual_agent}</span>
                </Tooltip>
              }
              value={TABS.VIRTUAL_AGENT}
            />
          )}
          {internalMode && isShowNewVAConfigurationUiEnabled && (
            <Tab
              label={
                <Tooltip title="Settings to enable additional features">
                  <span>{TABS_NAMES.internal_config}</span>
                </Tooltip>
              }
              value={TABS.INTERNAL_CONFIG}
            />
          )}
          <Tab
            label={
              <Tooltip title="Add/edit Leasing & Resident response">
                <span>{TABS_NAMES.responses}</span>
              </Tooltip>
            }
            value={TABS.RESPONSES}
          />
          <Tab
            label={
              <Tooltip title="Additional features to simplify setup">
                <span>{TABS_NAMES.setup}</span>
              </Tooltip>
            }
            value={TABS.SETUP}
          />
          {internalMode && !isShowNewVAConfigurationUiEnabled && (
            <Tab label={TABS_NAMES.config} value={TABS.CONFIG} />
          )}
        </Tabs>
        <Divider className={classes.divider} />
      </Box>

      {isShowNewVAConfigurationUiEnabled && (
        <div className={classes.buttons}>
          {internalMode && (
            <>
              <Button
                variant="outlined"
                size="large"
                startIcon={<GetAppIcon />}
                endIcon={isDownloading ? <CircularProgress size="20px" /> : ''}
                onClick={() => {
                  setIsDownloadModalOpen(true);
                  setDownloadButtonText('Products');
                }}
              >
                Download Products
              </Button>
              <Button
                variant="outlined"
                size="large"
                startIcon={<GetAppIcon />}
                endIcon={isDownloading ? <CircularProgress size="20px" /> : ''}
                onClick={() => {
                  setIsDownloadModalOpen(true);
                  setDownloadButtonText('Virtual Agent Config');
                }}
              >
                Download Virtual Agent Config
              </Button>
              <Button
                variant="outlined"
                size="large"
                startIcon={<GetAppIcon />}
                endIcon={isDownloading ? <CircularProgress size="20px" /> : ''}
                onClick={() => {
                  setIsDownloadModalOpen(true);
                  setDownloadButtonText('Internal Config');
                }}
              >
                Download Internal Config
              </Button>
            </>
          )}
          <Button
            variant="outlined"
            size="large"
            startIcon={<GetAppIcon />}
            endIcon={isDownloading ? <CircularProgress size="20px" /> : ''}
            onClick={() => {
              setIsDownloadModalOpen(true);
              setDownloadButtonText('Responses');
            }}
          >
            Download Responses
          </Button>
        </div>
      )}

      <VirtualAgentResponsesDownloadModal
        isOpen={isDownloadModalOpen}
        closeDialog={() => {
          setIsDownloadModalOpen(false);
        }}
        handleConfirmation={(propertyIds) =>
          handleDownloadConfirmation(propertyIds, true)
        }
        setErrorMessage={setDownloadModalErrorMessage}
        errorMessage={downloadModalErrorMessage}
        title="Select the properties to include in config export"
      />

      {internalMode && isShowNewVAConfigurationUiEnabled && (
        <>
          <LoadingOverlay
            open={isLoading}
            style={{ position: 'absolute', opacity: '0.5' }}
          />
          <TabPanel
            id="ProductsTabPanel"
            value={currentTab}
            index={TABS.PRODUCTS}
            className={classes.tabPanel}
          >
            <ProductEnabledSettings
              productsEnabled={productsEnabled}
              setProductsEnabled={setProductsEnabled}
              companyId={companyId}
            />
          </TabPanel>
          <TabPanel
            id="VirtualAgentConfigTabPanel"
            value={currentTab}
            index={TABS.VIRTUAL_AGENT}
            className={classes.tabPanel}
          >
            <VirtualAgentConfigSettings
              isInternalMode={internalMode}
              properties={productsEnabled}
              companyId={companyId}
            />
          </TabPanel>
          <TabPanel
            id="InternalConfigTabPanel"
            value={currentTab}
            index={TABS.INTERNAL_CONFIG}
            className={classes.tabPanel}
          >
            <InternalConfigSettings
              companyId={companyId}
              properties={productsEnabled}
            />
          </TabPanel>
        </>
      )}

      <TabPanel
        id="ResponsesTabPanel"
        value={currentTab}
        index={TABS.RESPONSES}
        className={classes.tabPanel}
      >
        <VirtualAgentResponses showNewUI={isShowNewVAConfigurationUiEnabled} />
      </TabPanel>

      <TabPanel
        id="SetupShortcutsTabPanel"
        value={currentTab}
        index={TABS.SETUP}
        className={classes.tabPanel}
      >
        <VirtualAgentSetupShortcuts companyId={companyId} />
      </TabPanel>

      {internalMode && !isShowNewVAConfigurationUiEnabled && (
        <TabPanel
          id="ConfigTabPanel"
          value={currentTab}
          index={TABS.CONFIG}
          className={classes.tabPanel}
        >
          <VirtualAgentConifg companyId={companyId} />
        </TabPanel>
      )}
    </ThemeProvider>
  );
};

export default NewVirtualAgent;
